<template>
  <div>
    <b-row class="justify-content-between mb-2">
      <b-col lg="6">
        <b-form-group
          class="mb-0 mt-1"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="search"
              type="search"
              placeholder="Search by email"
              @keyup.enter="advancedFilter.search = search"
            />
            <b-input-group-append>
              <b-button
                variant="outline-info"
                @click="clearSearch"
              >
                <feather-icon icon="XIcon" />
              </b-button>
              <b-button
                variant="outline-primary"
                :disabled="!search"
                @click="advancedFilter.search = search"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <data-table
      :module-name="'vpnLoginsList'"
      :fields="fields"
      :filters="advancedFilter"
      sort-by="createdAt"
      :sort-desc="true"
    >
      <template #cell(email)="data">
        <b-link
          v-if="Object.keys(data.item.user).length > 0"
          :to="{ name: 'user-detail', params: { id: data.item.user.id } }"
        >
          {{ data.item.email }}
        </b-link>
        <span v-else>
          {{ data.item.email }}
        </span>
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>
    </data-table>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BInputGroup, BInputGroupAppend, BFormInput, BFormGroup, BLink,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import DataTable from '@/components/ui/tables/DataTable.vue'
import table from '@/mixins/table'
import { formatDateTime, formatDate } from '@/plugins/formaters'

export default {
  components: {
    DataTable,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormGroup,
    BLink,
  },
  mixins: [table],
  data() {
    return {
      fields: [
        {
          key: 'email',
          sortable: true,
        },
        {
          key: 'ip',
          sortable: true,
        },
        {
          key: 'createdAt',
          sortable: true,
        },
      ],
      filterOn: ['email'],
      filter: null,
      advancedFilter: { search: '' },
      search: '',
    }
  },
  computed: {
    ...get('vpnLoginsList', ['data']),
  },
  methods: {
    formatDateTime,
    formatDate,
    clearSearch() {
      this.advancedFilter.search = ''
      this.search = ''
    },
  },
}
</script>
